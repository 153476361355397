/* global localStorage */
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const URL_KAMP_KEY = 'kamp';
const LOCAL_STORAGE_KAMP_KEY = 'snla-kamp';
const TTL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

// Function to set item with expiry
const setItemWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
};

// Function to get item with checking expiry
const getItemWithExpiry = key => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
};

// Check for 'kamp' param in URL
if (urlParams.has(URL_KAMP_KEY)) {
    const kampValue = urlParams.get(URL_KAMP_KEY);
    setItemWithExpiry(LOCAL_STORAGE_KAMP_KEY, kampValue, TTL);
}

// Function to be called on conversion
export const getKampValue = () => {
    const kampValue = getItemWithExpiry(LOCAL_STORAGE_KAMP_KEY);
    if (kampValue) {
        return kampValue;
    } else {
        return '';
    }
};
